var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header", { attrs: { hasLine: true } }),
        _c("div", { staticClass: "service", staticStyle: { padding: "0" } }, [
          _c("div", { staticClass: "frist-area" }, [
            _c(
              "div",
              {
                staticClass: "frist-area frist-area-top",
                staticStyle: { "margin-top": "77px" },
              },
              [
                _c("img", {
                  staticClass: "frist-area-banner",
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/m3/1-1.jpg",
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "frist-area-sub-title" }, [
                  _c("div", [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("car_list.the3.suitable"))),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("car_list.the4.style")))]),
                  ]),
                ]),
                _c("div", { staticClass: "frist-area-title" }, [
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("car_list.the3.slogan"))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "frist-area-buy-area" }, [
                  _c("table", [
                    _c("tr", [
                      _c("td", [
                        _c(
                          "span",
                          {
                            staticClass: "frist-area-buy-button",
                            on: {
                              click: function ($event) {
                                return _vm.goToBuy()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("car_list.buy_now")))]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "frist-area-top-bottom-div" }, [
                  _c("span", { staticClass: "frist-area-top-bottom-more" }, [
                    _c("img", {
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/others/LearnMore.png",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "frist-area frist-area-show" }, [
              _c("span", { staticClass: "frist-area-show-div-logo-area" }, [
                _c("img", {
                  staticClass: "frist-area-show-div-logo",
                  attrs: { src: "https://www.achencybe.com/img/pc/LOGOAC.png" },
                }),
              ]),
              _c("span", { staticClass: "frist-area-show-div-font-area" }, [
                _vm._v(_vm._s(_vm.$t("car_list.style"))),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "18px",
                    padding: "10px 0px 50px 0px",
                    "font-weight": "100",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("car_list.the3.show_page.technology_1"))
                  ),
                  _c("br"),
                  _vm._v(
                    " " + _vm._s(_vm.$t("car_list.the3.show_page.technology_2"))
                  ),
                  _c("br"),
                  _vm._v(
                    " " + _vm._s(_vm.$t("car_list.the3.show_page.technology_3"))
                  ),
                  _c("br"),
                  _c("span", { staticStyle: { "font-weight": "300" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("car_list.the3.show_page.technology_4"))
                    ),
                    _c("br"),
                  ]),
                ]
              ),
              _c("div", { staticClass: "frist-area-show-area-div" }, [
                _c("img", {
                  staticClass: "frist-area-show-area top",
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/m3/1-2-1.png",
                  },
                }),
                _c("img", {
                  staticClass: "frist-area-show-area bottom",
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/m3/1-2-2.png",
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "frist-area frist-area-goodlist" }, [
              _c(
                "div",
                { staticClass: "features-box" },
                [
                  _c("features", {
                    attrs: {
                      title: "car_list.the3.show_page.technology_5",
                      "sub-title": _vm.subTitle,
                      isThe3: true,
                      isHidden: true,
                      language: this.$i18n.locale,
                      list: _vm.list,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "font-size-padding30 font-size-12",
                  staticStyle: {
                    width: "100%",
                    "font-weight": "100",
                    "padding-top": "0",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("car_list.care_plus_remark_1"))),
                  _c("br"),
                  _vm._v(
                    " " + _vm._s(_vm.$t("car_list.care_plus_remark_2")) + " "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "carousel-box" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "left",
                      on: {
                        click: function ($event) {
                          return _vm.$refs.carousel.prev()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://www.achencybe.com/img/pc/detail/challenger/left.png",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "el-carousel",
                    {
                      ref: "carousel",
                      attrs: {
                        interval: 5000,
                        arrow: "never",
                        height: _vm.bannerHeight,
                      },
                    },
                    _vm._l(5, function (item) {
                      return _c("el-carousel-item", { key: item }, [
                        _c("img", {
                          attrs: {
                            src:
                              "https://www.achencybe.com/img/pc/m3/1-4-" +
                              item +
                              ".jpg",
                            alt: "",
                          },
                        }),
                      ])
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "right",
                      on: {
                        click: function ($event) {
                          return _vm.$refs.carousel.next()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://www.achencybe.com/img/pc/detail/challenger/right.png",
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "background-color": "#0c0c0c",
                    "padding-top": "100px",
                  },
                },
                [
                  _c("introduce", {
                    attrs: { introduceList: _vm.introduceList },
                  }),
                  _c("img", {
                    staticClass: "frist-m3-area-goodlist-image-area",
                    staticStyle: {
                      "max-width": "1380px",
                      width: "100%",
                      "margin-top": "100px",
                    },
                    attrs: {
                      src: "https://www.achencybe.com/img/pc/detail/challenger/bmw3_page_desinge.png",
                    },
                  }),
                  _c("detail-footer", {
                    attrs: {
                      title: "BMW THE 3",
                      img: "https://www.achencybe.com/img/pc/m3/1-8.png",
                    },
                    on: {
                      buy: function ($event) {
                        return _vm.goToBuy()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticStyle: { clear: "both" } }),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }