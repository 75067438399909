<template>
  <Page>
    <div class="home-page">
      <zui-header :hasLine="true"/>
      <div class="service" style="padding: 0">
        <div class="frist-area">
          <div class="frist-area frist-area-top" style="margin-top: 77px">
            <img
                src="https://www.achencybe.com/img/pc/m3/1-1.jpg"
                alt=""
                class="frist-area-banner"
            />
            <div class="frist-area-sub-title">
              <div><span> {{$t('car_list.the3.suitable')}}</span></div>
              <div><span>{{$t('car_list.the4.style')}}</span></div>
            </div>
            <div class="frist-area-title">
              <div><span>{{$t('car_list.the3.slogan')}}</span></div>
            </div>
            <div class="frist-area-buy-area">
              <table>
                <tr>
                  <td>
                    <span class="frist-area-buy-button" @click="goToBuy()"
                    >{{$t('car_list.buy_now')}}</span
                    >
                  </td>
                  <!--                  <td><span class="frist-area-learn-button" @click="goToLearn()">了解更多</span></td>-->
                </tr>
              </table>
            </div>
            <div class="frist-area-top-bottom-div">
              <span class="frist-area-top-bottom-more">
                <img src="https://www.achencybe.com/img/pc/others/LearnMore.png"/>
              </span>
            </div>
          </div>
          <div class="frist-area frist-area-show">
            <span class="frist-area-show-div-logo-area">
              <img
                  src="https://www.achencybe.com/img/pc/LOGOAC.png"
                  class="frist-area-show-div-logo"
              />
            </span>
            <span class="frist-area-show-div-font-area"
            >{{ $t('car_list.style') }}</span
            >
            <span
                style="
                font-size: 18px;
                padding: 10px 0px 50px 0px;
                font-weight: 100;
              "
            >
              {{$t('car_list.the3.show_page.technology_1')}}<br />
              {{$t('car_list.the3.show_page.technology_2')}}<br />
              {{$t('car_list.the3.show_page.technology_3')}}<br />

              <span style="font-weight: 300"> {{$t('car_list.the3.show_page.technology_4')}}<br/> </span>
            </span>
            <div class="frist-area-show-area-div">
              <img
                  src="https://www.achencybe.com/img/pc/m3/1-2-1.png"
                  class="frist-area-show-area top"
              />
              <img
                  src="https://www.achencybe.com/img/pc/m3/1-2-2.png"
                  class="frist-area-show-area bottom"
              />
            </div>
          </div>
          <div class="frist-area frist-area-goodlist">
            <div class="features-box">
              <features
                  title="car_list.the3.show_page.technology_5"
                  :sub-title="subTitle"
                  :isThe3="true"
                  :isHidden="true"
                  :language="this.$i18n.locale"
                  :list="list"
              />
            </div>
            <div
                class="font-size-padding30 font-size-12"
                style="width: 100%; font-weight: 100; padding-top: 0"
            >
              {{$t('car_list.care_plus_remark_1')}}<br/>
              {{$t('car_list.care_plus_remark_2')}}
            </div>
            <div class="carousel-box">
              <div class="left" @click="$refs.carousel.prev()">
                <img src="https://www.achencybe.com/img/pc/detail/challenger/left.png"/>
              </div>
              <el-carousel
                  :interval="5000"
                  arrow="never"
                  :height="bannerHeight"
                  ref="carousel"
              >
                <el-carousel-item v-for="item in 5" :key="item">
                  <img :src="'https://www.achencybe.com/img/pc/m3/1-4-' + item + '.jpg'" alt=""/>
                </el-carousel-item>
              </el-carousel>
              <div class="right" @click="$refs.carousel.next()">
                <img src="https://www.achencybe.com/img/pc/detail/challenger/right.png" alt=""/>
              </div>
            </div>

            <div style="background-color: #0c0c0c; padding-top: 100px">
              <introduce :introduceList="introduceList"/>
              <img
                  src="https://www.achencybe.com/img/pc/detail/challenger/bmw3_page_desinge.png"
                  class="frist-m3-area-goodlist-image-area"
                  style="max-width: 1380px; width: 100%; margin-top: 100px"
              />
              <detail-footer
                  title="BMW THE 3"
                  :img="'https://www.achencybe.com/img/pc/m3/1-8.png'"
                  @buy="goToBuy()"
              />
            </div>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <zui-footer/>
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import "../../less/bmw3-show.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
import features from "@/components/features";
import introduce from "@/components/introduce";
import DetailFooter from "@/components/detail-footer";

export default {
  components: {Page, ZuiHeader, ZuiFooter, features, introduce, DetailFooter},
  data() {
    return {
      height: "900px",
      bannerHeight: "1050px",
      list: [],
      subTitle: [
        "car_list.the3.show_page.technology_6"
      ],
      introduceList: [
        {
          img: "https://www.achencybe.com/img/pc/m3/1-5-1.jpg",
          title: "car_list.the3.show_page.introduces.item1.title",
          content:"car_list.the3.show_page.introduces.item1.content",
          contImg: "",
          subContent: [ "" ],
        },
        {
          img: "https://www.achencybe.com/img/pc/m3/1-5-2.jpg",

          title: "car_list.the3.show_page.introduces.item2.title",
          content:"car_list.the3.show_page.introduces.item2.content",
          contImg: "",
          subContent: [ "" ],
        },
        {
          img: "https://www.achencybe.com/img/pc/m3/1-5-3.jpg",
          title: "car_list.the3.show_page.introduces.item3.title",
          content:"car_list.the3.show_page.introduces.item3.content",
          contImg: "",
          subContent: [
            "car_list.the3.show_page.introduces.item3.sub_content.sub_1",
          ],
        },
        {
          img: "https://www.achencybe.com/img/pc/m3/1-5-4.jpg",
          title: "car_list.the3.show_page.introduces.item4.title",
          content:"car_list.the3.show_page.introduces.item4.content",
          contImg: "",
          subContent: [ "" ],
        },
      ],
    };
  },
  mounted() {
    this.height = window.innerHeight - 120 + "px";
    this.bannerHeight = (1664 / 3117) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.height = window.innerHeight - 120 + "px";
        this.bannerHeight = (1664 / 3117) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
      });
    });
    this.getCarDetail();
  },
  methods: {
    goToBuy: function () {
      this.$router.push({
        path: "/pages/public/OrderPage",
        query: {cartype: "carlistBMW3"},
      });
    },
    goToLearn: function () {
      this.$router.push({path: "/pages/bmw3/BMW3ShowPage"});
    },
    goToCarEPlus: function () {
      this.$router.push({path: "/pages/public/CarEPlusPage"});
    },
    getCarDetail() {
      this.$api
          .getCarDetail({
            carModelName: "The 3",
          })
          .then((res) => {
            if (res.code === 0) {
              res.data.listAccessoriesList.sort((a, b) => {
                return a.partsIndex - b.partsIndex
              })
              this.list = [
                {
                  img: "https://www.achencybe.com/img/pc/m3/1-3-5.jpg",
                  title: ["car_list.the3.show_page.items.item1.title"],
                  year: "car_list.the3.show_page.items.item1.year",
                  content:"car_list.the3.show_page.items.item1.content",
                  a: "car_list.the3.show_page.items.a",
                  price1: res.data.listAccessoriesList[14].partsPrice,
                  price2: res.data.listAccessoriesList[5].partsPrice,
                  price_dollar_1: res.data.listAccessoriesList[14].partsPriceDollar,
                  price_dollar_2: res.data.listAccessoriesList[5].partsPriceDollar,
                  // price: res.data.listAccessoriesList[5].partsPrice,
                  priceUnit: "",
                },
                {
                  img: "https://www.achencybe.com/img/pc/m3/1-3-1.jpg",
                  title: ["car_list.the3.show_page.items.item2.title"],
                  year: "car_list.the3.show_page.items.item2.year",
                  content:"car_list.the3.show_page.items.item2.content",
                  a: "car_list.the3.show_page.items.a",
                  price1: res.data.listAccessoriesList[9].partsPrice,
                  price2: res.data.listAccessoriesList[0].partsPrice,
                  price_dollar_1: res.data.listAccessoriesList[9].partsPriceDollar,
                  price_dollar_2: res.data.listAccessoriesList[0].partsPriceDollar,
                  // price: res.data.listAccessoriesList[0].partsPrice,
                  priceUnit: "",
                },
                {
                  img: "https://www.achencybe.com/img/pc/m3/1-3-2.jpg",
                  title: ["car_list.the3.show_page.items.item3.title"],
                  year: "car_list.the3.show_page.items.item3.year",
                  content:"car_list.the3.show_page.items.item3.content",
                  price1: res.data.listAccessoriesList[10].partsPrice,
                  price2: res.data.listAccessoriesList[1].partsPrice,
                  price_dollar_1: res.data.listAccessoriesList[10].partsPriceDollar,
                  price_dollar_2: res.data.listAccessoriesList[1].partsPriceDollar,
                  // price: res.data.listAccessoriesList[1].partsPrice,
                  priceUnit: "",
                },
                {
                  img: "https://www.achencybe.com/img/pc/m3/1-3-6.jpg",
                  title: ["car_list.the3.show_page.items.item4.title"],
                  year: "car_list.the3.show_page.items.item4.year",
                  content:"car_list.the3.show_page.items.item4.content",
                  price1: res.data.listAccessoriesList[15].partsPrice,
                  price2: res.data.listAccessoriesList[6].partsPrice,
                  price_dollar_1: res.data.listAccessoriesList[15].partsPriceDollar,
                  price_dollar_2: res.data.listAccessoriesList[6].partsPriceDollar,
                  // price: res.data.listAccessoriesList[6].partsPrice,
                  priceUnit: "",
                },
                {
                  img: "https://www.achencybe.com/img/pc/m3/1-3-7.jpg",
                  title: ["car_list.the3.show_page.items.item5.title"],
                  year: "car_list.the3.show_page.items.item5.year",
                  content:"car_list.the3.show_page.items.item5.content",
                  price1: res.data.listAccessoriesList[16].partsPrice,
                  price2: res.data.listAccessoriesList[7].partsPrice,
                  price_dollar_1: res.data.listAccessoriesList[16].partsPriceDollar,
                  price_dollar_2: res.data.listAccessoriesList[7].partsPriceDollar,
                  // price: res.data.listAccessoriesList[7].partsPrice,
                  priceUnit: "",
                },
                {
                  img: "https://www.achencybe.com/img/pc/m3/1-3-8.jpg",
                  title: ["car_list.the3.show_page.items.item6.title"],
                  year: "car_list.the3.show_page.items.item6.year",
                  content:"car_list.the3.show_page.items.item6.content",
                  price1: res.data.listAccessoriesList[17].partsPrice,
                  price2: res.data.listAccessoriesList[8].partsPrice,
                  price_dollar_1: res.data.listAccessoriesList[17].partsPriceDollar,
                  price_dollar_2: res.data.listAccessoriesList[8].partsPriceDollar,
                  // price: res.data.listAccessoriesList[8].partsPrice,
                  priceUnit: "",
                },
                {
                  img: "https://www.achencybe.com/img/pc/m3/1-3-3.jpg",
                  title: ["car_list.the3.show_page.items.item7.title"],
                  year: "car_list.the3.show_page.items.item7.year",
                  content:"car_list.the3.show_page.items.item7.content",
                  price1: res.data.listAccessoriesList[11].partsPrice,
                  price2: res.data.listAccessoriesList[2].partsPrice,
                  price_dollar_1: res.data.listAccessoriesList[11].partsPriceDollar,
                  price_dollar_2: res.data.listAccessoriesList[2].partsPriceDollar,
                  // price: res.data.listAccessoriesList[2].partsPrice,
                  priceUnit: "",
                },
                {
                  img: "https://www.achencybe.com/img/pc/m3/1-3-4.jpg",
                  title: ["car_list.the3.show_page.items.item8.title"],
                  year: "car_list.the3.show_page.items.item8.year",
                  content:"car_list.the3.show_page.items.item8.content",
                  price1: res.data.listAccessoriesList[12].partsPrice,
                  price2: res.data.listAccessoriesList[3].partsPrice,
                  price_dollar_1: res.data.listAccessoriesList[12].partsPriceDollar,
                  price_dollar_2: res.data.listAccessoriesList[3].partsPriceDollar,
                  // price: res.data.listAccessoriesList[3].partsPrice,
                  priceUnit: "",
                },
              ];
            }
          })
          .catch((err) => {
            console.error("getCarDetail", err)
          });
    },
  },
};
</script>
